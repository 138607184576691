.blue-hero {
  min-height: 280px;
  margin-bottom: 40px;

  .blue-hero-bg {
    width: 100%;
    background-color: map_get($theme-colors, "light-blue");
    position: absolute;
    top: 0;
    left: 0;
    min-height: 280px;
  }
}
