.page-clubs-signup {
  background-color: map_get($theme-colors, "grey-0");

  .title-section {
    padding: 40px 0;
  }

  .day-row-item {
    .day-row-checkbox-container {
      display: flex;
      align-items: center;
      height: 37px;
    }

    input[type="time"] {
      padding-top: 5px;
      padding-bottom: 5px;
      min-height: unset;
    }

    .closed-on-day {
      line-height: 37px;
    }
  }

  .remove-court-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .form-control {
    min-height: 47px;
  }

  .places-autocomplete-container input {
    min-height: 33px;
  }

  .form-check {
    padding-left: 0;

    input {
      display: none;
    }

    label {
      position: relative;
      padding-left: 2em;
      cursor: pointer;

      &:before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
        border: solid 1px #d2d3db;
      }
    }

    input:checked + label {
      &:before {
        background-color: #1fb0f7;
        border: 1px solid #1fb0f7;
      }

      &:after {
        content: "";
        width: 10px;
        height: 6px;
        position: absolute;
        top: calc(50% - 0.25em);
        left: 5px;
        border: 2px solid #fcfff4;
        border-top: none;
        border-right: none;
        background: 0 0;
        transform: rotate(-45deg);
      }
    }
  }
}
