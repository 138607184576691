.search-btn {
  color: white;
  min-width: 100px;

  :disabled {
    background-color: #d2d3db;
    color: #fff;
    cursor: not-allowed;
  }
}

.club-item {
  color: white;
  width: 100%;
}

.club-photo-item {
  position: relative;
  .delete-upload {
    cursor: pointer;
    background-color: white;
    padding-right: 24px;
    padding-left: 24px;
    border-radius: 3px;
    display: flex;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 20px;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;
      font-size: 15px;
    }
  }
}

.delete-photo-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  .delete-photo-modal-container {
    background-color: map_get($theme-colors, "white");
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    min-width: 250px;
    min-height: 150px;
    margin: 24px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .delete-photo-modal-message {
      margin: 30px 30px 0 30px;
      text-align: center;
    }

    .delete-photo-modal-buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      button {
        color: map_get($theme-colors, "white");
        &:hover {
          color: map_get($theme-colors, "black");
        }
      }

      .delete-photo-modal-primary-btn {
        background-color: map_get($theme-colors, "red-1");
        border-color: map_get($theme-colors, "red");
        margin-right: 20px;
        min-width: 80px;
        &:hover {
          background-color: map_get($theme-colors, "red-extra-faded");
        }
      }

      .delete-photo-modal-secondary-btn {
        min-width: 80px;
      }
    }
  }
}
