@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.ErrorMessage {
  color: map_get($theme-colors, "red");
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: map_get($theme-colors, "white");
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.StripeElement {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 1rem !important;
  line-height: 1.6;
  width: 100%;
  padding: 12px 0.75rem;
  font-weight: 400;
  color: map_get($theme-colors, "black");
  background: map_get($theme-colors, "white");
  border-radius: 6px;
  background-clip: padding-box;
  border: 1px solid map_get($theme-colors, "grey-3");
  appearance: none;
}

.StripeElement::placeholder {
  color: map_get($theme-colors, "grey-3");
}

.StripeElement--focus {
  border-color: map_get($theme-colors, "primary");
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.label {
  padding: 4px 9px;
  background-color: map_get($theme-colors, "orange");
  font-size: 12px;
  color: map_get($theme-colors, "white");
  border-radius: 5px;
}
