.player-badges-section {
  .player-badge {
    width: 80px;
    text-align: center;

    .img-container {
      img {
        width: 100%;
        margin-bottom: 16px;
      }

      .badge-count {
        font-weight: bold;
        background-color: #097faf;
        border-radius: 10px;
        padding: 2px 10px;
        bottom: 8px;
        color: #fff;
        font-size: 12px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .badge-label {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
