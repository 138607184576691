.section-referrals {
  .content-section {
    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        padding-left: calc((100vw - #{$container-max-width}) / 2);
      }
    }

    padding-right: 24px;

    @include media-breakpoint-up(sm) {
      padding-right: 50px;
    }

    @include media-breakpoint-up(md) {
      padding-right: 140px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 250px;
    }
  }

  .graphic-container {
    margin-bottom: 200px;

    .graphic {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      width: 300px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;

      .graphic {
        left: -150px;
        transform: unset;
        width: auto;
      }
    }

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }
}
