.multiSelectContainer {
  .highlightOption,
  li:hover {
    background: #1fb0f7 !important;
  }

  .searchWrapper {
    padding-right: 45px;
    padding-left: 0.75rem;
    position: relative;

    &:after {
      content: "";
      height: 100%;
      width: 19px;
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-bottom: 5px;
      background-position: center;
      background-image: url("/img/icons/arrow-down.png");
      background-repeat: no-repeat;
      background-size: 19px;
    }
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: black;
  }
}

.pseudo-select {
  min-height: 47px;
  border: 1px solid #d2d3db;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  &:focus {
    border: solid 1px #1fb0f7;
  }

  //&:focus-within {
  //    border: solid 1px #1FB0F7;
  //}
  //
  //&:after {
  //    content: "";
  //    height: 100%;
  //    width: 40px;
  //    position: absolute;
  //    top: 0;
  //    right: 0;
  //    padding-top: 5px;
  //    padding-bottom: 5px;
  //    border-left: 1px solid #d2d3da;
  //    background-position: center;
  //    background-image: url('/img/icons/arrow-down.png');
  //    background-repeat: no-repeat;
  //}

  select {
    padding: 12px 0.75rem 12px 0.75rem;
    //appearance: none !important;
    outline: none !important;
  }
}
