.premium-section {
  background-color: map_get($theme-colors, "light-blue");
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: calc(100vh - $header-height);
}

.premium-selection-item-active {
  cursor: pointer;
  transition: border 300ms ease-in-out;
  border-left: 8px solid map_get($theme-colors, "primary");
}

.premium-selection-item {
  padding: 30px;
  background-color: map_get($theme-colors, "white");
  align-items: center;
  &:hover {
    cursor: pointer;
    transition: border 300ms ease-in-out;
    border-left: 8px solid map_get($theme-colors, "primary");
  }
}

.premium-location-container {
  background-color: white;
}

.premium-user-card-container {
  width: 360px;
  @include media-breakpoint-down("sm") {
    width: 272px;
  }
}

.premium-user-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .avatar {
    border-radius: 50px;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .premium-sport-container {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: map_get($theme-colors, "transparent-green");
    border-radius: 6px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 15px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: map_get($theme-colors, "light-green");
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 18px;
      font-weight: bold;
      color: map_get($theme-colors, "light-green");
    }
  }
  .premium-achivements-container {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      height: 45px;
    }
  }
}

.premium-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: map_get($theme-colors, "white");
  position: relative;
  align-self: center;
  z-index: 1000;
  height: 32px;
  width: 80px;
  border-radius: 16px;

  h2 {
    font-size: 14px;
  }
}

.premium-membership {
  .title {
    h4 {
      color: map_get($theme-colors, "orange");
    }
  }
  .payment-button {
    background-color: map_get($theme-colors, "orange") !important;
    border: none;
  }
}

.membership-payment-type-active {
  background-color: map_get($theme-colors, "transparent-orange") !important;
  border: 2px solid map_get($theme-colors, "orange") !important;
}

.membership-payment-type {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: map_get($theme-colors, "white");
  border: 2px solid map_get($theme-colors, "white");
  margin-bottom: 8px;
  padding: 32px;
  width: 100%;
  p {
    margin: 0;
  }
  .title-container {
    > p {
      font-weight: bold;
      color: map_get($theme-colors, "light-green");
    }

    ul {
      margin: 0;
      margin-left: -12px;
    }
  }

  .mostPopular {
    padding: 3px 10px;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
    padding-left: 15px;
    position: absolute;
    left: -11px;
    top: 8px;
    background: map_get($theme-colors, "primary");
    color: map_get($theme-colors, "white");
  }
  .mostPopular:before,
  .mostPopular:after {
    content: "";
    position: absolute;
  }
  .mostPopular:before {
    height: 0;
    width: 0;
    bottom: -8.5px;
    left: 0.1px;
    border-top: 9px solid #097faf;
    border-left: 9px solid transparent;
  }
}

.secondary-text {
  color: map_get($theme-colors, "grey-1");
}

.sign-up-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 32px !important;
  width: 120px !important;
  border-radius: 6px;
  font-size: 14px;
  color: white;
  background-color: map_get($theme-colors, "primary");
  font-weight: 500;
  &:hover {
    color: white;
  }
}

.payment-form {
  background-color: map_get($theme-colors, "white");
  padding: 20px;
}

.install-the-app-container {
  background-image: linear-gradient(
    90deg,
    transparent 75%,
    map_get($theme-colors, "primary") 25%
  );
  margin-top: -80px;
  min-height: 100vh;
  padding: 20vh 0;
  @include media-breakpoint-down("md") {
    background-image: none;
    margin-top: 0;
    height: unset;
  }
}

.app-install-container {
  border: 1px solid map_get($theme-colors, "black");
  border-radius: 12px;
}

.selection-container {
  .subtitle {
    display: inline-block;
    width: 75%;

    @include media-breakpoint-down("lg") {
      width: 100%;
    }
  }
}

.discount-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-container {
    background-color: map_get($theme-colors, "white");
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    width: 880px;
    min-height: 455px;
    margin: 24px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .info-container {
      margin-left: 32px;
      margin-right: 32px;
      max-width: 540px;
      margin-top: 32px;
      & h3 {
        color: map_get($theme-colors, "black");
        font-size: 16px;
        text-align: center;
        & span {
          color: map_get($theme-colors, "orange");
        }
      }
      & p {
        color: map_get($theme-colors, "grey-1");
        font-size: 14px;
        text-align: center;
        margin: 0;
      }
    }
    .buttons-container {
      display: flex;
      margin-top: 32px;
      @include media-breakpoint-down("sm") {
        flex-direction: column;
      }
      & h3 {
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        margin: 0;
      }
      .button {
        border-radius: 6px;
        width: 270px;
        height: 56px;
        padding: 11px 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 16px;
      }
      .pay-more-button {
        background-color: map_get($theme-colors, "white") !important;
        border: 1px solid map_get($theme-colors, "grey-3");
        margin-bottom: 40px;
        color: map_get($theme-colors, "black");
      }
    }
  }
}

.offer-header-container {
  background-image: url("/img/premium/img_offer.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: 100%;
  min-height: 200px;
  @include media-breakpoint-down("sm") {
    min-height: 230px;
  }
  .offer-header-content {
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    padding: 40px;
    margin-bottom: 20px;
    color: map_get($theme-colors, "white");
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    & h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
    & p {
      font-size: 16px;
      font-weight: bold;
    }
    .separator {
      border-bottom: 2px solid map_get($theme-colors, "white");
      width: 42px;
      margin: 16px 0;
    }
  }
}
.offer-card-container {
  padding: 5px;
  min-width: 336px;
  height: 100px;
  border-radius: 12px;
  background-color: map_get($theme-colors, "black");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  z-index: 1;
  @include media-breakpoint-down("sm") {
    width: 270px;
  }
  & h2 {
    color: map_get($theme-colors, "white");
    font-size: 24px;
    text-align: center;
    color: map_get($theme-colors, "orange");
  }
  & p {
    color: map_get($theme-colors, "white");
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
  }
}

.btn-orange {
  background-color: map_get($theme-colors, "orange");
  border: 1px solid map_get($theme-colors, "orange");
  color: map_get($theme-colors, "white");
}
