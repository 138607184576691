.left-right-content-container-text {
  h2 {
    font-size: 20px;
    margin-bottom: 24px;
    font-weight: bolder;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      margin-bottom: 40px;
    }
  }

  .left-right-underline {
    width: 60px;
    height: 4px;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      width: 120px;
      height: 10px;
      margin-bottom: 0;
    }
  }
}
