.upload-multiple-file-target-container {
  text-align: center;
  font-size: 14px;
  margin-bottom: 32px;

  .delete-upload {
    cursor: pointer;
    background-color: #e7e7e7;
    border-radius: 50%;
    display: flex;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 12px;
      font-size: 12px;
    }
  }

  .upload-multiple-file-target {
    margin-left: auto;
    margin-right: auto;
    width: 275px;
    height: 150px;
    border: dashed map_get($theme-colors, "primary") 2px;
    border-radius: 12px;
    background-color: map_get($theme-colors, "light-blue");
  }
}

.crop-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .crop-modal-container {
    background-color: map_get($theme-colors, "white");
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    min-width: 250px;
    min-height: 150px;
    margin: 24px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .crop-info-container {
      margin-left: 32px;
      margin-right: 32px;
      max-width: 540px;
      margin-top: 32px;
    }

    .crop-modal-buttons-container {
      display: flex;
      align-items: flex-start;
      margin: 10px;

      .crop-modal-primary-btn {
        margin-right: 10px;
      }
    }
  }
}
