.upload-file-target-container {
  text-align: center;
  margin-bottom: 32px;

  .upload-file-target {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: map_get($theme-colors, "light-blue");
    border: dashed map_get($theme-colors, "primary") 2px;
    margin: 0 auto 16px;
    cursor: pointer;
  }
}
