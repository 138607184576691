.styled-bullet-list {
  .bullet-point {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
}
