// Body
$body-bg: #fff;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 2,
  ),
  6: (
    $spacer * 3,
  ),
  7: (
    $spacer * 5,
  ),
  8: (
    $spacer * 8,
  ),
);
$enable-negative-margins: true;

// Typography
$font-family-sans-serif: Montserrat, sans-serif;
$font-size-base: 1rem;
$headings-font-weight: 800;
$line-height-base: 1.6;

// Colors
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e83030;
//$orange: #f6993f;
//$yellow: #ffed4a;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;

$theme-colors: (
  "primary": #1fb0f7,
  "light-blue": #e8f7fe,
  "white": #fff,
  "black": #000,
  "dark-blue": #1a324c,
  "grey-white-bg": #e8e8e8,
  "grey-0": #f6f6f8,
  "grey-1": #9da1b3,
  // Used a lot in text
  "grey-2": #f4f4f4,
  "grey-3": #d2d3db,
  // Used a lot in text
  "transparent": transparent,
  "red": #f5003d,
  "red-1": #ff3835,
  "red-faded": #ff0000b6,
  "red-extra-faded": #ff0000b6,
  "green": #38c172,
  "light-green": #0d1110,
  "teal": #4dc0b5,
  "transparent-green": #e5fcf4,
  "orange": #fdbe53,
  "transparent-orange": rgba(253, 190, 83, 0.2),
);

$link-decoration: none;
$link-color: map_get($theme-colors, "dark-blue");
$body-color: map_get($theme-colors, "black");

$card-spacer-x: 1.5rem;
$card-border-width: 0;

$alert-border-width: 0;

// ==== Input
$input-border-color: #d2d3db;
$input-border-width: 1px;
$input-focus-box-shadow: unset;
$input-focus-border-color: #1fb0f7;
$input-padding-y: 10px;
$form-label-font-weight: 600;
$input-border-radius: 6px;

$btn-focus-box-shadow: 0;
$btn-focus-width: 0;

// ==== Modals
$modal-header-border-width: 0;
$modal-content-border-width: 0;
$modal-header-padding: 1.5rem;
$modal-inner-padding: 1.5rem;
$modal-footer-margin-between: 0;

$modal-backdrop-bg: map_get($theme-colors, "dark-blue");
$modal-backdrop-opacity: 0.1;

// ==== Button
$btn-border-radius: 6px;

// ==== Carousels
$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;
$carousel-indicator-active-bg: map_get($theme-colors, "dark-blue");
$carousel-indicator-opacity: 0.2;

// ==== Plugins
// == Menu
$hamburger-padding-y: 0;
$hamburger-padding-x: 0;
$hamburger-layer-width: 1.5em;
$hamburger-layer-height: 2px;
$hamburger-layer-color: map_get($theme-colors, "black");
$hamburger-layer-border-radius: 0;
$hamburger-active-layer-color: map_get($theme-colors, "black");
// Material icons
$material-icons-font-size: 1rem;

// Tooltips
$tooltip-bg: map_get($theme-colors, "primary");

// Misc
$header-height: 80px;
