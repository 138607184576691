html {
  scroll-behavior: auto;
  min-width: 320px;

  body {
    min-width: 320px;
    background-color: map_get($theme-colors, "white");

    header {
      height: $header-height;
      transition: height 0.4s;
      position: fixed;
      z-index: 100;
      width: 100%;
      background-color: #fff;
      top: 0;
      overflow: hidden;

      .header-height {
        height: $header-height;
      }

      &.mobile-menu-open {
        height: 100%;
      }

      .header-logo {
        .material-icons {
          font-size: 24px;
        }
      }

      .hamburger {
        height: 18px;
      }
    }

    #__next {
      margin-top: $header-height;
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - $header-height);

      & > main {
        flex: 1;
      }

      .hero {
        height: 320px;
        max-height: 75vh;

        @include media-breakpoint-up("md") {
          height: 800px;
        }

        .container .row {
          min-height: 75vh;
        }

        .hero-image-container {
          z-index: 1;
        }

        h1 {
          font-size: 40px;
        }
      }

      .testimonial-section {
        padding: 0 0 180px;

        .testimonial-title-row {
          margin: 0 0 50px;
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 50%;
          bottom: 0;
          background-color: #fff;
          z-index: 0;
        }
      }

      footer {
        padding-top: 50px;

        @include media-breakpoint-up(md) {
          padding-top: 170px;
        }
      }
    }

    .mw-1920 {
      max-width: 1920px;
    }

    .error-field {
      font-size: 14px;
      color: map_get($theme-colors, "red");
      margin: 8px 0 16px;
      text-decoration: underline;
      font-weight: 600;
    }

    .line-clamp-2 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    select {
      appearance: auto !important;
    }

    .modal-content .close.icon {
      margin: 0;
      top: -20px;
      right: -20px;

      &:after {
        content: "";
        width: 20px;
        height: 1px;
        background-color: #333;
        display: block;
        transform: rotate(45deg);
      }

      &:before {
        content: "";
        width: 20px;
        height: 1px;
        background-color: #333;
        display: block;
        transform: rotate(-45deg);
      }
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .cursor-default {
      cursor: default;
    }

    .absolute-vertical-centered {
      top: 50%;
      transform: translateY(-50%);
    }

    .left-0 {
      left: 0;
    }

    .section-padding-top,
    .section-padding-y {
      padding-top: 40px !important;

      @include media-breakpoint-up(md) {
        padding-top: 180px !important;
      }
    }

    .section-padding-bottom,
    .section-padding-y {
      padding-bottom: 40px !important;

      @include media-breakpoint-up(md) {
        padding-bottom: 180px !important;
      }
    }

    .section-margin-top,
    .section-margin-y {
      margin-top: 40px !important;

      @include media-breakpoint-up(md) {
        margin-top: 180px !important;
      }
    }
    .font-size-12 {
      font-size: 12px;
    }

    .font-size-14 {
      font-size: 14px;
    }

    .section-margin-bottom,
    .section-margin-y {
      margin-bottom: 40px !important;

      @include media-breakpoint-up(md) {
        margin-bottom: 180px !important;
      }
    }

    .hero-height {
      height: 320px;
      max-height: 75vh;

      @include media-breakpoint-up("md") {
        height: 800px;
      }
    }

    .coaches-stringers-hero-img-cont {
      font-size: 0;

      > div {
        height: 100%;
      }
    }

    .font-size-0 {
      font-size: 0;
    }

    .font-size-14 {
      font-size: 14px;
    }

    .font-size-16 {
      font-size: 16px;
    }

    .font-size-20 {
      font-size: 20px;
    }

    .font-size-40 {
      font-size: 40px;
    }

    @include media-breakpoint-up(md) {
      .font-size-md-16 {
        font-size: 16px;
      }

      .font-size-md-24 {
        font-size: 24px;
      }

      .font-size-md-40 {
        font-size: 40px;
      }
    }

    @include media-breakpoint-up(lg) {
      .font-size-lg-16 {
        font-size: 16px;
      }

      .font-size-lg-24 {
        font-size: 24px;
      }

      .font-size-lg-40 {
        font-size: 40px;
      }
    }

    .rp-button {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    @include media-breakpoint-down(sm) {
      .container {
        padding-right: 24px;
        padding-left: 24px;
      }

      .row {
        margin-left: -24px;
        margin-right: -24px;
      }

      .row > * {
        padding-right: 24px;
        padding-left: 24px;
      }
    }
  }
}
