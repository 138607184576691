.sport-card {
  padding: 30px 24px;
  border-radius: 6px;

  .level {
    padding: 4px 12px 4px 4px;
    font-size: 12px;

    &.up {
      background-color: #09ca9a;
    }
    &.down {
      background-color: #ff3835;
    }

    .MuiSvgIcon-root {
      font-size: 18px;
    }
  }
}
