.uploaded-files-list {
  .add-item {
    border: dashed map_get($theme-colors, "primary") 1px;
    color: map_get($theme-colors, "primary");
    font-size: 14px;
  }

  .delete-upload {
    cursor: pointer;
    background-color: #e7e7e7;
    border-radius: 50%;
    display: flex;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    right: 2px;
    top: 2px;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 12px;
      font-size: 12px;
    }
  }

  .uploaded-item {
    width: 60px;
    height: 35px;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 16px;
    overflow: hidden;

    &.selected {
      border: solid map_get($theme-colors, "primary") 2px;
    }
  }
}
