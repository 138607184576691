.image-left-footer {
  margin: 0 auto;
  max-width: 1920px;

  .image-container {
    position: relative !important;
    font-size: 0;
    height: 100%;

    > div {
      height: 100%;
    }
  }

  .content-section {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-breakpoint-up(lg) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        padding-right: calc((100vw - #{$container-max-width}) / 2);
      }
    }

    @media (min-width: 1920px) {
      padding-right: calc((1920px - 1320px) / 2);
    }
  }
}

@include media-breakpoint-up("md") {
  .image-left-footer {
    margin: 150px auto;

    .image-container {
      height: auto;
      width: 50%;
      max-width: 670px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute !important;

      > div {
        height: auto;
      }
    }
  }
}
