body {
  .home-page {
    .hero .home-hero-image {
      filter: brightness(0.8);
      display: none;
      opacity: 0;
      transition: opacity 1s;

      &.active {
        opacity: 1;
      }
    }

    .hero-line-height {
      line-height: 65px;
    }

    .find-local-players-section {
      .image-bg-block {
        height: 70%;
        top: 50%;

        @include media-breakpoint-down(md) {
          left: 50%;
          transform: translateX(-50%) scale(0.95);
          width: 85% !important;
        }
      }
    }

    .home-how-it-works {
      @include media-breakpoint-down(md) {
        padding-top: 130px !important;
      }
    }
  }
}
