.coaches-stringers-hero {
  .container {
    .blue-content-section {
      position: unset;

      @include media-breakpoint-up(md) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @include media-breakpoint-down(md) {
      max-width: unset !important;
      padding: 0 !important;
    }
  }
}
