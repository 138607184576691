.benefits-section {
  padding: 40px 0 80px;

  @include media-breakpoint-up(md) {
    padding: 160px 0;
  }
}

.exclusive-access-section {
  color: #fff;

  &:before {
    content: "";
    width: 100%;
    position: absolute;
    height: 80%;
    left: 0;
    background-color: map_get($theme-colors, "dark-blue");
    bottom: -10%;
    z-index: 0;
  }

  .exclusive-access-image-container {
    width: 200px;
    margin: auto;
  }

  @include media-breakpoint-up(md) {
    color: #000;

    &:before {
      content: "";
      width: 50%;
      height: 700px;
      bottom: -50%;
    }

    .exclusive-access-image-container {
      width: auto;
      margin: unset;
    }
  }
}

.pins-container {
  width: 280px;
  margin: auto;

  @include media-breakpoint-up(md) {
    width: auto;
    margin: unset;
  }
}

.waiting-list-section {
  overflow-x: hidden;

  img {
    z-index: 1;
  }

  .waiting-list-image-container {
    width: 200px;
    margin: auto;

    @include media-breakpoint-up(md) {
      width: auto;
      margin: unset;
    }
  }

  .content-section {
    padding: 24px 24px 40px;

    &:before {
      content: "";
      width: calc(100vw + 80px);
      height: 140%;
      position: absolute;
      background-color: map_get($theme-colors, "light-blue");
      left: -80px;
      bottom: 0;
      z-index: 0;
    }

    @include media-breakpoint-up(md) {
      padding: 70px 75px;

      &:before {
        height: 100%;
        top: 0;
        left: 0;
        bottom: unset;
      }
    }

    @include media-breakpoint-up(xl) {
      padding: 140px 150px;
    }
  }
}
