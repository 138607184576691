.stats-section {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    z-index: 1;
    background-color: map_get($theme-colors, "grey-0");
  }

  &.dark-blue {
    &:before {
      background-color: map_get($theme-colors, "dark-blue");
    }
  }

  .container {
    z-index: 2;

    .stat-item-container {
      box-shadow: 0 16px 40px 0 rgba(31, 31, 49, 0.1);
      background-color: #fff;
      border-radius: 0;

      @include media-breakpoint-up(md) {
        border-radius: 4px;
      }
    }
  }
}
