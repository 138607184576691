.how-it-works {
  .how-it-works-list {
    .item {
      border-radius: 8px;
      background-color: rgb(77 124 176 / 10%);

      .item-number {
        background-color: rgb(255 255 255 / 10%);
        font-weight: bolder;
        width: 32px;
        height: 32px;
        text-align: center;
      }
    }
  }
}
