.info-ball-container {
  width: 120px;
  margin: 0 8px 32px;

  .info-ball {
    width: 80px;
    height: 80px;
    margin: 0 auto 32px;

    svg {
      transform: scale(0.5);
    }

    @include media-breakpoint-up(md) {
      width: 160px;
      height: 160px;

      svg {
        transform: unset;
      }
    }
  }

  p {
    font-weight: bold;
    font-size: 14px;
  }

  @include media-breakpoint-up(md) {
    width: 160px;
    margin: 0 16px 32px;
  }

  @include media-breakpoint-up(md) {
    width: 210px;
  }
}
