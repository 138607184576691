.form-block {
  margin-bottom: 24px;
  padding: 24px;

  .form-extra-info {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  @include media-breakpoint-up(lg) {
    padding: 56px 76px;
  }

  @include media-breakpoint-up(xl) {
    padding: 56px 152px;
  }
}
