.react-tel-input {
  .form-control {
    width: 100% !important;
    border: 1px solid #d2d3db !important;

    &:focus {
      border-color: #1fb0f7 !important;
      outline: 0;

      + .flag-dropdown {
        border-left-color: #1fb0f7;
        border-top-color: #1fb0f7;
        border-bottom-color: #1fb0f7;
      }
    }
  }

  .flag-dropdown {
    border: 1px solid #d2d3db;
  }
}
