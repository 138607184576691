.app-banner-section {
  margin-top: 130px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .app-banner-text {
    padding-top: 170px;
    padding-bottom: 95px;

    @include media-breakpoint-up(md) {
      padding-top: 125px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .app-store-links a {
    color: #fff;
  }

  .image-container {
    position: absolute;

    top: -120px;
    width: 300px;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(md) {
      top: 50%;
      width: auto;
      left: unset;
      transform: translateY(-50%);
    }
  }
}
