.timeline-section {
  .MuiTimeline-root {
    .MuiTimelineItem-root {
      min-height: 100px;

      &.timeline-separator {
        min-height: 20px;
      }

      .MuiTimelineOppositeContent-root {
        flex: 0 0 auto;
        width: 25%;
        display: flex;
        align-items: center;
      }

      .MuiTimelineSeparator-root {
        flex: 0 0 auto;
        width: 60px;

        .MuiTimelineDot-root {
          margin-left: auto;
          margin-right: auto;
          background-color: #d2d3db;
          margin-bottom: 0;
          box-shadow: unset;
        }

        .MuiTimelineConnector-root {
          width: 1px;
          background-color: #d2d3db;
        }

        @include media-breakpoint-up(md) {
          width: 80px;
        }
      }

      .MuiTimelineContent-root {
        display: flex;
        align-items: center;
      }

      &.last-item-section {
        .MuiTimelineOppositeContent-root {
          width: 0;
          padding: 0;
        }

        .MuiTimelineSeparator-root {
          width: 60px;
          max-width: 100%;

          @include media-breakpoint-up(md) {
            width: 290px;
          }

          @include media-breakpoint-up(lg) {
            width: 371px;
          }

          @include media-breakpoint-up(xl) {
            width: 429px;
          }

          @include media-breakpoint-up(xxl) {
            width: 491px;
          }
        }

        .MuiTimelineContent-root {
          width: 0;
          padding: 0;
        }
      }
    }
  }
}
