.page-coaches-signup {
  background-color: map_get($theme-colors, "grey-0");

  .title-section {
    padding: 40px 0;
  }

  .sport-selection-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .sport-item {
      text-align: center;
      cursor: pointer;
      margin: 0 4px 24px;
      display: flex;
      width: 100%;

      .icon {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: map_get($theme-colors, "grey-0");
        margin-left: auto;
        margin-right: auto;

        img {
          width: 22px;
          height: 22px;
        }
      }

      .label {
        font-weight: bold;
        font-size: 14px;
        flex: 1 0 calc(100% - 34px - 20px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 20px;
        background-color: map_get($theme-colors, "white");
        color: map_get($theme-colors, "black");
        text-transform: capitalize;
      }

      input:checked ~ .label {
        color: map_get($theme-colors, "primary");
      }

      input:checked ~ .icon {
        background-color: map_get($theme-colors, "primary");

        img {
          filter: invert(1);
        }
      }

      @include media-breakpoint-up(md) {
        display: inline-block;
        width: 95px;

        .icon {
          width: 72px;
          height: 72px;
          margin-bottom: 16px;

          img {
            width: auto;
            height: auto;
          }
        }

        .label {
          display: block;
          margin-left: 0;
        }
      }
    }
  }

  .form-control {
    min-height: 47px;
  }

  .places-autocomplete-container input {
    min-height: 33px;
  }
}
