.blue-box-image-text-container {
  padding-top: 100px;

  .blue-image-container {
    position: relative;
    font-size: 0;

    &:before {
      content: "";
      width: 50%;
      height: 50%;
      position: absolute;
      display: block;
      background-color: map_get($theme-colors, "primary");
      right: -24px;
      top: -24px;
    }

    @include media-breakpoint-up(sm) {
      left: -100px;

      &:before {
        right: -50px;
        top: -50px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 220px;
  }
}
