.rp-carousel {
  box-shadow: 0 11px 34px 0 rgba(26, 50, 76, 0.14);
  padding: 40px 24px 100px;

  .carousel-indicators {
    li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: $carousel-indicator-width;
      height: $carousel-indicator-height;
      padding: 0;
      margin-right: $carousel-indicator-spacer;
      margin-left: $carousel-indicator-spacer;
      text-indent: -999px;
      cursor: pointer;
      background-color: $carousel-indicator-active-bg;
      background-clip: padding-box;
      border: 0;
      border-radius: 50%;
      // Use transparent borders to increase the hit area by 10px on top and bottom.
      border-top: $carousel-indicator-hit-area-height solid transparent;
      border-bottom: $carousel-indicator-hit-area-height solid transparent;
      opacity: $carousel-indicator-opacity;
      @include transition($carousel-indicator-transition);

      &.active {
        opacity: 1;
      }
    }
  }

  .rp-carousel-top {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(md) {
    padding: 40px 100px 80px;
    border-radius: 4px;
  }
}
