.image-with-mirrored-color {
  position: relative;
  margin-bottom: 50px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: map_get($theme-colors, "primary");
    width: 80%;
    height: 70%;
    bottom: -52px;
    left: -32px;
  }
}

.info-message-container {
  padding: 40px;
  background-color: map_get($theme-colors, "grey-0");
  width: 577px;
  text-align: center;
  p {
    margin-bottom: 0;
  }
}

.sign-up-player-page {
  width: 576px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.sign-up-action-buttons-container {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.benefits-cards-container {
  background-color: map_get($theme-colors, "light-blue");
  > p {
    font-weight: 14px;
  }
}
