.featured-by-section {
  padding: 160px 0;

  h5 {
    margin-bottom: 70px;
  }

  .swiper-wrapper {
    align-items: center;
  }
}

.ourPartners-by-section {
  padding-bottom: 160px;

  h4 {
    margin-bottom: 40px;
    font-weight: bold;
  }
}
