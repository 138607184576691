.page-contact {
  .graphic {
    position: relative;

    .graphic-container {
      font-size: 0;
    }

    &:before {
      content: "";
      width: 100%;
      height: 400px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: map_get($theme-colors, "light-blue");
    }

    @include media-breakpoint-up(md) {
      &:before {
        width: 90%;
      }
    }
  }

  .form-control {
    min-height: 47px;
  }

  .form-section {
    textarea {
      resize: unset;
    }

    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        padding-right: calc((100vw - #{$container-max-width}) / 2);
      }
    }

    @media (min-width: 1920px) {
      padding-right: calc((1920px - 1320px) / 2);
    }
  }

  .contact-success-section {
    .contact-success-container {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        height: 300px;
        width: 100%;
        display: block;
        background-color: map_get($theme-colors, "light-blue");
        bottom: -15px;
        left: 0;
      }

      @include media-breakpoint-up(md) {
        &:before {
          bottom: -190px;
        }
      }
    }
  }
}
