.verify-email-container {
  background-image: linear-gradient(
    90deg,
    transparent 65%,
    map_get($theme-colors, "primary") 35%
  );
  min-height: 100vh;
  @include media-breakpoint-down("md") {
    background-image: none;
    margin-top: 0;
    height: unset;
  }
}
