.matches-hero {
  height: 400px;

  .blue-hero-content {
    height: 400px;

    .matches-hero-image {
      width: 100%;
      height: 100%;
    }

    .matches-hero-info {
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      text-align: left;
      padding-bottom: 40px;

      h1 {
        font-size: 40px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .row {
      .col-12 {
        padding: 0 !important;

        h1 {
          font-size: 32px;
        }
      }
    }
  }
}
