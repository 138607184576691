.page-careers {
  .hero-section {
    .hero-text {
      padding-top: 100px;
      padding-bottom: 100px;
      padding-right: 100px;
      bottom: 0;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 60%;
      }

      @each $breakpoint, $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          padding-left: calc(1rem + ((100vw - #{$container-max-width}) / 2));
        }
      }

      @media (min-width: 1920px) {
        padding-left: calc(1rem + ((1920px - 1320px) / 2));
      }
    }
  }

  .team-success-section {
    .main-text {
      margin-bottom: 16px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 96px;
      }
    }
  }

  .benefits-card {
    border-radius: 24px;
  }

  .roles-section {
    .header-title {
      margin-bottom: 80px;
    }
  }

  .careers-testimonial {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 60%;
      background-color: map_get($theme-colors, "dark-blue");
      top: 0;
      left: 0;
    }
  }
}
