.app-store-links {
  display: flex;

  svg {
    display: inline-block;
    width: 100%;
  }

  &.stacked {
    display: block;

    a {
      margin-right: 0 !important;
      margin-left: 0 !important;
      display: block !important;

      &:first-child {
        margin-bottom: 16px;
      }

      svg {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: auto;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;

    a {
      margin-right: 0 !important;
      margin-left: 0 !important;
      display: block !important;

      &:first-child {
        margin-bottom: 16px;
      }

      svg {
        width: 100%;

        &.app-store-bordered {
          border: solid 1px currentColor;
          border-radius: 8px;
        }
      }
    }
  }
}
