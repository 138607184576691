.page-about {
  .grey-shadow {
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      left: -80%;
      display: block;
      position: absolute;
      background-color: map_get($theme-colors, "grey-0");
      z-index: 0;
    }
  }

  .employees-section {
    @include media-breakpoint-down(md) {
      padding-bottom: 80px;
      .employee {
        max-width: 50%;
      }
    }
  }

  .founder-row {
    margin-top: 30px;
    margin-bottom: 100px;

    .founder-image {
      margin-bottom: 70px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        background-color: map_get($theme-colors, "primary");
        width: 100%;
        height: 100%;
        top: 40px;
        left: -60px;

        @include media-breakpoint-up(md) {
          top: 80px;
          left: -120px;
        }
      }

      &.flipped {
        margin-bottom: 30px;

        &:before {
          left: unset;
          right: -24px;
          top: -24px;

          @include media-breakpoint-up(md) {
            right: -120px;
            top: 40px;
          }
        }
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}
