@media print {
  .printable-header,
  .printable-footer {
    display: none;
  }
  .page-terms-and-conditions {
    font-size: 12px;

    .container {
      max-width: unset !important;
      padding: 50px;

      h1 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
      }
    }

    .section-padding-y.col-md-8 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
